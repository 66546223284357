<template>
  <div>每日入选策略</div>
  <el-card shadow="always" style="margin-top: 10px;">
    <div class="title">日期选择</div>
    <div style="display: flex;  margin: 15px 0;">
      <div class="day-select">
        <div>交易日选择</div>
        <div style="margin: 0 20px;">
          <el-select v-model="date" class="m-2" placeholder="选择周期操作" @change="changeDate">
            <el-option v-for="item in dateOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <!-- <div class="confirm-btn" @click="search">确定</div> -->
      </div>
      <div class="update-btn" @click="update">确认修改</div>
    </div>
  </el-card>

  <el-card shadow="always" style="margin-top: 20px;">
    <div class="header">
      <div class="header-title">交易策略共识</div>
      <div class="add-meeting" @click="addMeeting">+新增共识</div>
    </div>
    <el-table :data="meetingData" style="width: 100%" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333',}" :cell-style="{ 'text-align': 'center', color: '#333' }">
      <el-table-column type="index" label="序号" width="80">
      </el-table-column>
      <el-table-column prop="consensusPerson" label="共识人" width="280">
      </el-table-column>
      <el-table-column prop="consensusContent" label="共识内容">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <span @click="updateItem(scope.row)" style="color: blue;cursor: pointer;">编辑</span>
          <span @click="deleteItem(scope.row)" style="margin-left: 30px; color: red;cursor: pointer;">删除</span>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-card shadow="always" style="margin-top: 20px;">
    <div class="title">预备策略仓</div>
    <textarea v-model="strategyWarehouse"></textarea>
  </el-card>

  <el-card shadow="always" style="margin-top: 20px;">
    <div class="header">
      <div class="header-title">入选股票清单</div>
      <div class="header-btns">
        <div class="del-btn" @click="delAll">全部删除</div>
        <div class="add-btn" @click="add">+批量增加</div>
      </div>
    </div>
    <el-table ref="singleTableRef" :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" style="width: 100%;margin-top: 20px;height: 400px;overflow-y: auto;" :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'bold',
            color: '#333',
          }" :cell-style="{ 'text-align': 'center', color: '#333' }">
      <el-table-column type="index" label="序号" width="100">
        <template #default="scope">
          <span>{{ (currentPage-1)*pageSize + scope.$index + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column property="stockName" label="股票名称" />
      <el-table-column property="stockCode" label="股票代码" />
      <el-table-column property="positionSuggestion" label="仓位建议" width="200">
        <template #default="scope">
          <el-input v-model.lazy="scope.row.positionSuggestion" @input="scope.row.positionSuggestion = checkIsNum(scope.row.positionSuggestion)" placeholder="请输入" style="width: 80px;" />%
        </template>
      </el-table-column>
      <el-table-column property="stopProfitRatio" label="止盈止损比例" width="300">
        <template #default="scope">
          <el-input v-model.lazy="scope.row.stopProfitRatio" @input="scope.row.stopProfitRatio = checkIsNum(scope.row.stopProfitRatio)" placeholder="请输入" style="width: 80px;" />% /
          <el-input v-model.lazy="scope.row.stopLossRatio" @input="scope.row.stopLossRatio = checkIsNum(scope.row.stopLossRatio)" placeholder="请输入" style="width: 80px;" />%
        </template>
      </el-table-column>
      <el-table-column property="period" label="周期" width="140">
        <template #default="scope">
          <el-select v-model="scope.row.period" class="m-2" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column property="address" label="操作">
        <template #default="scope">
          <span style="color: red;cursor: pointer;" @click="delItem(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <el-dialog title="批量增加股票" v-model="dialogVisible" :width="720">
      <el-form ref="form" label-width="180px" label-position="left" class="stock-form">
        <el-form-item label="上传股票列表">
          <div style="display:flex;">

            <el-upload class="upload-demo" accept='.xlsx,.xls' :before-upload="beforeUpload" :http-request="upload" action :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :file-list="fileList">
              <el-button size="small" style="padding:0;color: #0f40f5;background:transparent;border:none;fontSize:14px;fontWeight:bold;height:35px;">
                {{fileList.length===0?'点击上传':'重新上传'}}</el-button>
              <!-- <el-button size="small" style="padding:0;color: #0f40f5;background:transparent;border:none;fontSize:14px;fontWeight:bold">点击上传</el-button> -->
            </el-upload>

            <a :href=baseUrl download='stock.xlsx' target="view_window" class="link" style="color: #0f40f5;fontSize:14px;fontWeight:bold;margin-left:20px;cursor: pointer;text-decoration: none;">下载模板</a>

          </div>
        </el-form-item>
        <el-form-item label="股票数量(成功/失败)">
          <div>{{successStockList.length}}/{{failStockList.length}}</div>
        </el-form-item>
        <el-form-item label="股票清单">
          <div class="stockMenu">
            <div class="changeBtn">
              <div :class="{common:status===item}" @click="changeBtn(item)" v-for="(item,index) in changeBtns" :key="index">{{item}}</div>
            </div>
            <div>
              <el-table :height="stockList.length>4?'249':49+40*stockList.length" :data="stockList" border style="width: 100%">
                <el-table-column type="index" label="序号" width="150">
                </el-table-column>
                <el-table-column prop="stockCode" label="股票代码" width="150">
                </el-table-column>
                <el-table-column prop="stockName" label="股票名称" width="150">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirm" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog :title="meetingDialogTitle" v-model="meetingVisible" :width="720">
      <el-form ref="mForm" :model="meetingForm" :rules="meetingRules" label-width="90px" label-position="left" class="stock-form" style="width: 500px;margin: auto;">
        <el-form-item label="共识人" prop="person">
          <el-input v-model="meetingForm.person" maxlength="20" />
        </el-form-item>
        <el-form-item label="共识内容" prop="content">
          <textarea v-model="meetingForm.content" maxlength="1000" style="height:180px;"></textarea>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirmMeeting" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="meetingVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>

  </el-card>
</template>

<script>
import { onMounted, reactive, toRefs, ref, nextTick } from 'vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { dailyStrategyAdd, getDailyStrategyList, getPeriodicLengthEnum, modifyDailyStrategy, getDateList } from "../../../api/selectionStrategy/dailySelectionStrategy";
import axios from "axios";
const http = axios.create({
  baseURL: "/api",
  headers: {
    "api-version": "1.0.0",
    "Content-Type": "multipart/form-data",
    'token': localStorage.getItem('user_token'),
  }
});
http.interceptors.request.use(res => {
  res.headers.token = localStorage.getItem('user_token');
  return res;
});
const baseUrl = location.protocol + "//" + location.host + '/static/stock.xlsx';
export default {
  setup() {
    const mForm = ref();
    const state = reactive({
      date: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      options: [],
      dialogVisible: false,
      fileList: [],
      successStockList: [],
      failStockList: [],
      stockList: [],
      changeBtns: ['处理成功', '处理失败'],
      status: '处理成功',
      dateOptions: [],
      meetingData: [],
      meetingVisible: false,
      meetingForm: {
        id: '',
        person: '',
        content: ''
      },
      meetingRules: {
        person: [{ required: true, message: '共识人不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '共识内容不能为空', trigger: 'blur' }]
      },
      meetingDialogTitle: '新增共识',
      strategyWarehouse: ''
    })
    onMounted(() => {
      //获取日期选择
      getDate();
      //周期长度枚举
      getPeriod();
    })
    const getDate = () => {
      let params = {
        num: 20
      }
      getDateList({ params }).then((res) => {
        if (res.resultState == 200) {
          res.data.forEach((item) => {
            state.dateOptions.push({
              label: item,
              value: item
            })
          })
          state.date = state.dateOptions[0].value;
          search();
        }
      }).catch((err) => {

      });
    }
    const changeDate = () => {
      search();
    }
    const add = () => {
      state.dialogVisible = true;
      state.fileList = []
      state.stockList = []
      state.successStockList = []
      state.failStockList = []
    }
    const delAll = () => {
      if (state.tableData.length === 0) return
      ElMessageBox.alert(`确定删除全部股票？`, "提示", {
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        //全部删除列表
        state.tableData = [];
        state.total = state.tableData.length;
        // update();
      }).catch(() => { });

    }
    const delItem = (row) => {
      let delIndex = '';
      state.tableData.forEach((item, index) => {
        if (item.stockCode === row.stockCode) {
          delIndex = index;
        }
      });
      state.tableData.splice(delIndex, 1);
      state.total = state.tableData.length;
    }
    const update = () => {
      let strategyConsensusDTOS = [];
      state.meetingData.forEach(item => {
        strategyConsensusDTOS.push({
          consensusContent: item.consensusContent,
          consensusPerson: item.consensusPerson
        })
      })
      let params = {
        date: state.date,
        stockList: state.tableData,
        strategyConsensusDTOS,
        strategyWarehouse: state.strategyWarehouse
      }
      modifyDailyStrategy(params).then((res) => {
        if (res.resultState == 200) {
          ElMessage({
            message: res.msg,
            type: "success",
          });
          search();
        }
      }).catch((err) => {

      });
    }
    // 上传
    const upload = (param) => {
      const formData = new FormData();
      formData.append("file", param.file)
      handleUpload(formData)
    }
    const beforeUpload = (file, fileList) => {
      const isExcel =
        file.name.split(".")[1] === "xlsx" ||
        file.name.split(".")[1] === "xls"
      if (!isExcel) {
        ElMessage({
          message: "只能上传xlsx、xls文件",
          type: "error",
        });
      }
      return isExcel;
    };
    const handleRemove = (file, fileList) => {
    };
    const handlePreview = (file) => {
    };
    const handleExceed = (files, fileList) => {
      state.fileList = files
      const formData = new FormData();
      formData.append("file", files[0])
      handleUpload(formData, files)
    };
    const handleUpload = (formData, files) => {
      http.post("/cts/back/mainLineStock/uploadStock", formData).then((res) => {
        if (res.data.resultState === "200") {
          state.status = '处理成功'
          state.stockList = res.data.data.successDo
          state.successStockList = res.data.data.successDo
          state.failStockList = res.data.data.unsuccessfulDo
        } else {
          ElMessage({
            message: res.data.msg,
            type: "error",
          });
          state.fileList = []
          state.stockList = []
          state.successStockList = []
          state.failStockList = []
        }
      })
        .catch((err) => { });
    }
    const beforeRemove = (file, fileList) => {
      // ElMessageBox.alert(`确定移除 ${file.name}？`, "提示", {
      //   confirmButtonText: "确定",
      //   type: "warning",
      // }).then(() => {
      state.stockList = []
      state.successStockList = []
      state.failStockList = []
      // }).catch(() => { });
    }
    const changeBtn = (item) => {
      state.status = item
      if (item === '处理成功') {
        state.stockList = state.successStockList
      } else {
        state.stockList = state.failStockList
      }
    }
    const confirm = () => {
      if (state.successStockList.length === 0) {
        ElMessage({
          message: "请上传处理成功的股票清单",
          type: "error",
        });
        return
      }
      // let params = {
      //   date: state.date,
      //   stockList: state.successStockList
      // }
      state.successStockList.forEach(item => {
        item.period = "1";
        item.positionSuggestion = 10;
        item.stopLossRatio = -2.5;
        item.stopProfitRatio = 2.5;
      })
      //数组去重
      let oldStockCodes = [];
      state.tableData.forEach(item => {
        oldStockCodes.push(item.stockCode);
      })

      let arr = state.successStockList.filter(item => {
        return !oldStockCodes.includes(item.stockCode);
      })
      state.tableData.push(...arr)
      state.dialogVisible = false;
      state.total = state.tableData.length;

      // dailyStrategyAdd(params).then((res) => {
      //   if (res.resultState == 200) {
      //     ElMessage({
      //       message: res.msg,
      //       type: "success",
      //     });
      //     state.dialogVisible = false;
      //     search();
      //   }
      // }).catch((err) => {

      // });
    }
    const search = () => {
      state.currentPage = 1;
      state.pageSize = 10;
      let params = {
        date: state.date
      }
      getDailyStrategyList({ params }).then((res) => {
        if (res.resultState == 200) {
          const { stockList, strategyConsensusDTOS, strategyWarehouse } = res.data;
          state.tableData = stockList;
          state.total = state.tableData.length;
          state.meetingData = strategyConsensusDTOS;
          state.strategyWarehouse = strategyWarehouse;
        }
      }).catch((err) => {

      });
    }
    const getPeriod = () => {
      getPeriodicLengthEnum().then((res) => {
        if (res.resultState == 200) {
          state.options = res.data;
        }
      }).catch((err) => {

      });
    }
    const checkIsNum = (val) => {
      const t = val.charAt(0);

      val = val.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      if (t === '-') {
        val = '-' + val;
      }
      return val;
    }
    const handleSizeChange = (val) => {
      state.pageSize = val;
    };

    const handleCurrentChange = (val) => {
      state.currentPage = val;
    };

    const addMeeting = () => {
      state.meetingDialogTitle = '新增共识';
      state.meetingVisible = true;
      nextTick(() => {
        mForm.value.resetFields();
      })
    };

    const updateItem = (row) => {
      state.meetingDialogTitle = '修改共识';
      state.meetingVisible = true;
      nextTick(() => {
        mForm.value.resetFields();
        state.meetingForm.person = row.consensusPerson;
        state.meetingForm.content = row.consensusContent;
        state.meetingForm.id = row.id;
      })
    };

    const deleteItem = (row) => {
      let arr = state.meetingData.filter(item => {
        return item.id !== row.id;
      })
      state.meetingData = arr;
    };

    const confirmMeeting = () => {
      mForm.value.validate((valid) => {
        if (valid) {
          if (state.meetingDialogTitle === '新增共识') {
            state.meetingData.push({
              consensusPerson: state.meetingForm.person,
              consensusContent: state.meetingForm.content,
              id: Date.now()
            })
          } else if (state.meetingDialogTitle === '修改共识') {
            state.meetingData.forEach(item => {
              if (item.id === state.meetingForm.id) {
                item.consensusPerson = state.meetingForm.person;
                item.consensusContent = state.meetingForm.content;
              }
            })
          }
          state.meetingVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }

    return {
      ...toRefs(state),
      add,
      delAll,
      delItem,
      update,
      upload,
      beforeUpload,
      handleExceed,
      beforeRemove,
      changeBtn,
      confirm,
      search,
      changeDate,
      baseUrl,
      checkIsNum,
      handleSizeChange,
      handleCurrentChange,
      addMeeting,
      updateItem,
      deleteItem,
      confirmMeeting,
      mForm
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  box-sizing: border-box;
  &:focus-visible {
    outline: none;
  }
}
.title {
  font-weight: bold;
  font-size: 16px;
}
.day-select {
  display: flex;
  align-items: center;
  .confirm-btn {
    background: rgb(56, 162, 138);
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 0 0 20px 0;
  .header-title {
    font-weight: bold;
    font-size: 16px;
  }
  .add-meeting {
    background: rgb(56, 162, 138);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
  }
  .header-btns {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    .del-btn {
      color: blue;
      margin-right: 30px;
    }
    .add-btn {
      background: rgb(56, 162, 138);
      color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
}
.update-btn {
  background: rgb(56, 162, 138);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.stockMenu {
  .changeBtn {
    width: 200px;
    display: flex;
    border: 1px solid #ccc;
    text-align: center;
    margin-bottom: 20px;
    div {
      width: 100px;
      box-sizing: border-box;
      cursor: pointer;
      &.common {
        background-color: #93d2f3;
        color: #fff;
      }
    }
    div:first-child {
      border-right: 1px solid #ccc;
    }
  }
}
.page {
  float: right;
  margin: 20px;
}
</style>