import { post, get, put, del } from '../../request/http';

export function dailyStrategyAdd(params) {
    return post('/cts/back/dailySelectionStrategy/add', params)
}

export function getDailyStrategyList(params) {
    return get('/cts/back/dailySelectionStrategy/selectModify', params)
}

export function getPeriodicLengthEnum(params) {
    return get('/cts/back/dailySelectionStrategy/periodicLengthEnum', params)
}

export function modifyDailyStrategy(params) {
    return post('/cts/back/dailySelectionStrategy/modify', params)
}

export function getDateList(params) {
    return get('/cts/back/dailySelectionStrategy/dateList', params)
}

